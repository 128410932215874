@import '../../../../scss/theme-bootstrap';

.cart-overlay__wrapper {
  display: block;
  visibility: visible;
  opacity: 1;
  #{$rdirection}: 0;
  width: 100%;
  a {
    text-decoration: none;
    font-weight: bold;
  }
  .site-utils__dropdown {
    &--content {
      @include breakpoint($landscape-up) {
        position: absolute;
      }
    }
  }
}

.cart-confirm-wrapper {
  padding: 10px 25px 25px;
  outline: none;
  text-align: $ldirection;
  &__close,
  &__edit {
    float: $rdirection;
    text-decoration: underline;
    text-transform: uppercase;
  }
  .cart-header,
  .emptycart-header {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .left-copy {
    float: $ldirection;
    font-weight: bold;
    width: 45%;
    text-transform: uppercase;
  }
  .right-copy {
    float: $rdirection;
    font-weight: bold;
    width: 45%;
    text-align: $rdirection;
    div {
      display: inline-block;
    }
  }
  .quickshop__button {
    width: 100%;
    padding: 0;
    margin: 10px 0;
  }
  .promos {
    text-align: center;
    &__content {
      margin: 10px 0;
      font-weight: 700;
    }
    &__link {
      text-decoration: underline;
    }
  }
  .cart-product {
    margin: 15px 0;
    border-bottom: 1px solid $color-lighter-gray;
    overflow-y: scroll;
    width: 311px;
  }
  &__close {
    text-align: $rdirection;
  }
}

.cart-confirm--empty {
  .cart-header,
  .cart-checkout,
  .subtotal {
    display: none;
  }
}

.cart-confirm--nonempty {
  .emptycart-header,
  .cart-continue-shopping {
    display: none;
  }
}
